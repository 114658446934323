<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">活动信息</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<!-- 左侧规则 -->
				<div class="viewCL">
					<div class="viewTitle">活动规则</div>
					<el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm" id="addHuodong" size="small">
						<el-form-item label="活动名称" prop="name" class="w300" label-width="70px">
							<el-input v-model="ruleForm.name" placeholder="请输入活动名称" size="small" style="width: 168px;">
							</el-input>
						</el-form-item>
						<el-form-item label="顾客类型" prop="activityUser" label-width="70px">
							<el-radio-group v-model="ruleForm.activityUser">
								<el-radio label="0">全部</el-radio>
								<el-radio label="1">普通会员</el-radio>
								<el-radio label="2">充值会员</el-radio>
								<el-radio label="3">员工</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="新人专享" prop="newMember" label-width="70px">
							<el-radio-group v-model="ruleForm.newMember">
								<el-radio label="1">是</el-radio>
								<el-radio label="2">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="参与次数" label-width="70px">
							<el-input-number v-model="ruleForm.useCount" :min="0" size="small" :step-strictly="true">
							</el-input-number>
						</el-form-item>
						<el-form-item label="活动时间" label-width="70px">
							<el-date-picker v-model="ruleForm.activity_time" type="datetimerange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" size="small">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="活动说明" prop="remark" label-width="70px">
							<el-input type="textarea" v-model="ruleForm.remark" resize="none" style="width: 400px;"
								rows="4" size="small" placeholder="请输入活动说明"></el-input>
						</el-form-item>
						<el-form-item label="活动类型" prop="consume_type" label-width="70px">
							<el-radio-group v-model="ruleForm.consume_type" @change="handelConsumeTypeClick">
								<el-radio label="1">满减</el-radio>
								<el-radio label="2">折扣</el-radio>
								<el-radio label="3">特价</el-radio>
							</el-radio-group>
						</el-form-item>
						<!-- 活动规则 -->
						<div class="typeView">
							<!-- 满减 -->
							<div class="reduction" v-if="ruleForm.consume_type == '1'">
								<el-form-item prop="name" label="满" label-width="70px">
									<div style="display: flex;align-items: center;">
										<el-input v-model="ruleForm.fullReductionCon" style="width: 120px;"
											placeholder="请输入金额" size="small"></el-input>
										<div style="margin: 0 10px;">减</div>
										<el-input v-model="ruleForm.fulleductionValue" style="width: 120px;"
											placeholder="请输入金额" size="small"></el-input>
									</div>
								</el-form-item>
							</div>
							<!-- 折扣 -->
							<el-form-item label="折扣:" label-width="70px" v-if="ruleForm.consume_type == '2'">
								<div style="display: flex;align-items: center;">
									<el-input v-model="ruleForm.discountValue" style="width: 120px;" placeholder="请输入折扣"
										size="small"></el-input>
									<div style="margin-left: 10px;">
										{{ruleForm.discountValue==''?'':(ruleForm.discountValue*10).toFixed(1)}}折</div>
								</div>
							</el-form-item>
							<!-- 特价 -->
							<el-form-item label="特价:" label-width="70px" v-if="ruleForm.consume_type == '3'">
								<div style="display: flex;align-items: center;">
									<el-input v-model="ruleForm.specialValue" style="width: 140px;"
										placeholder="请输入特价金额" size="small"></el-input>
									<div style="margin-left: 10px;">元</div>
								</div>
							</el-form-item>
						</div>
					</el-form>

				</div>

				<!-- 右侧添加产品 -->
				<div class="viewCR">
					<!-- 添加商品 -->
					<div class="shopView">
						<div class="viewTitle">添加产品</div>
						<el-radio-group v-model="ruleForm.shopType" class="mt20" @change="handelShopTypeClick">
							<el-radio label="0">全部商品</el-radio>
							<el-radio label="1">品牌</el-radio>
							<el-radio label="2">商品类目</el-radio>
							<el-radio label="3">指定商品</el-radio>
						</el-radio-group>
						<!-- 选择类目 -->
						<div class="com" v-if="ruleForm.shopType == 2">
							<div class="comClass">
								<div class="comTitle">商品类目:</div>
								<el-cascader :options="firstcatgoryData"
									:props="{ label:'name', multiple: true, value:'id',children:'itemList'}" clearable
									@change="handleCatgoryChange" collapse-tags ref="firstcatgory" style="260px"
									size="small"></el-cascader>
							</div>
							<div class="comView">
								<div class="comItem" v-for="(item,index) in selectCategory" :key="index">
									<span>{{item.name}}</span>
									<span class="el-icon-close" @click="handleCategoryClose(index)"></span>
								</div>
							</div>
						</div>
						<!-- 选择品牌 -->
						<div class="com" v-if="ruleForm.shopType == 1">
							<div class="comClass">
								<div class="comTitle">品牌:</div>
								<el-select v-model="brandValue" filterable placeholder="请选择品牌" style="width: 140px;"
									size="small" @change="handleBrandChange">
									<el-option v-for="item in brandData" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="comView">
								<div class="comItem" v-for="(item,index) in selectBrand" :key="index">
									<span>{{item.name}}</span>
									<span class="el-icon-close" @click="handleBrandClose(index)"></span>
								</div>
							</div>
						</div>
						<!-- 选择商品 -->
						<div v-if="ruleForm.shopType == 3">
							<div style="margin: 10px 0;display: flex;">
								<el-button type="primary" size="small" @click="dialogProduct_state = true">选择商品
								</el-button>
								<el-button size="small" @click="handleBatchShopDeleteClick()">批量删除</el-button>
							</div>
							<el-table :data="shopTable" border style="width: 100%"
								@selection-change="handleSelectShopChange">
								<el-table-column type="selection" width="55" align="center">
								</el-table-column>
								<el-table-column fixed prop="newData.secondCategoryName" label="类目" align="center">
								</el-table-column>
								<el-table-column prop="newData.productName" label="名称" align="center">
								</el-table-column>
								<el-table-column prop="newData.modelValue" label="规格" align="center">
									<template slot-scope="scope">
										{{scope.row.newData.modelValue==''?'--':scope.row.newData.modelValue}}
									</template>
								</el-table-column>
								<el-table-column label="条形码" prop="newData.barCode" align="center">
									<template slot-scope="scope">
										{{scope.row.newData.barCode == ''?'--':scope.row.newData.barCode}}
									</template>
								</el-table-column>
								<el-table-column fixed="right" label="操作" align="center">
									<template slot-scope="scope">
										<el-button size="small" @click="handelDeleteGoodsClick(scope.$index)">删除
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>

			</div>
			<div class="viewB" v-if="routerType != 1">
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交
				</el-button>
			</div>
		</div>
		<!-- 商品列表 -->
		<div v-if="dialogProduct_state">
			<dialogProduct @submit="submit_dialogProduct" :tree="tree" :selectedList="shopTable"></dialogProduct>
		</div>
	</div>
</template>

<script>
	import dialogProduct from "../../components/dialogProduct.vue"
	export default {
		components:{
			dialogProduct
		},
		data() {
			return {
				routerType: this.$route.query.type, // 1查看 2编辑
				activitInfo: this.$route.query.activitInfo,  //跳转详情
				ruleForm: {
					name: '', //活动名称
					activityUser: '0', //用户类型 0全部 1普通会员 2充值会员 3员工
					newMember: '1', //新人专享
					activity_time: '', //活动时间集合
					remark: '', //活动说明
					consume_type: '1', //优惠类型  1满减 2折扣 3特价
					shopType: '0', //商品类型    0全部商品 1品牌 2商品类目 3指定商品
					useCount: 0, //参与次数
					fullReductionCon: '', //满减条件
					fulleductionValue: '', //满减值
					discountValue: '', //折扣
					specialValue: '', //特价
				},
				brandData: [], //品牌数据
				brandValue: '', //选中品牌值
				selectBrand: [], //选中品牌
				firstcatgoryData: [], //获取分类列表
				selectCategory: [], //选中类目
				shopTable: [], //商品表格
				selectShopTable: [], //选中商品表格
				tree:	[{code:'goodstype'},{code:'servicetype'},{code:'combotype'},{code:'virtualtype'},{code:'coupontype'},{code:'pettype'}],  //商品分类
				dialogProduct_state:false,   //商品弹框
			}
		},
		mounted() {
			
			if(this.activitInfo != undefined){
				let info = JSON.parse(this.activitInfo);
				let ruleForm = this.ruleForm;
				ruleForm.name = info.name;  //活动名称
				ruleForm.activityUser = info.activityUser+'';  //用户类型
				ruleForm.newMember = info.newMember+'';  //是否新人专享
				ruleForm.activity_time = [new Date(info.beginTime*1000),new Date(info.endTime*1000)];  //活动时间
				ruleForm.remark = info.remark == null?'':info.remark;  //活动说明
				ruleForm.useCount = info.useCount;
				ruleForm.consume_type = info.consumeItem.consumeType+'';
				if(info.consumeItem.consumeType == 1){   //满减
					ruleForm.fullReductionCon = info.consumeItem.fullReduction.fullReductionConditions;
					ruleForm.fulleductionValue = info.consumeItem.fullReduction.fullReductionValue;
				}else if(info.consumeItem.consumeType == 2){  //折扣
					ruleForm.discountValue = info.consumeItem.otherInfo.otherInfoValue;
				}else if(info.consumeItem.consumeType == 3){  //特价
					ruleForm.specialValue = info.consumeItem.otherInfo.otherInfoValue;
				}
				
				//产品
				ruleForm.shopType = info.consumeItem.type+'';
				//品牌
				if(info.consumeItem.type == 1){
					this.getBrandData()
					this.selectBrand = info.consumeItem.param;
				}
				//类目
				if(info.consumeItem.type == 2){
					this.getClassData()
					this.selectCategory = info.consumeItem.param;
				}
				//指定商品
				if(info.consumeItem.type == 3){
					let arr = [];
					info.consumeItem.param.map((item)=>{
						arr.push({
							newData:{
								"productId": item.id,
								"productName": item.name,
								"firstCategoryName": item.firstCategoryName,
								"secondCategoryName": item.secondCategoryName,
								"modelValue":item.skuModel,
								"barCode":item.barcode
							}
						})
					})
					this.shopTable = arr;
				}
				
			}
		},
		methods: {
			//提交
			handleSubmitClick() {
				let ruleForm = this.ruleForm;
				if(ruleForm.name == ''){
					this.$message({
						type: 'error',
						message: '请输入活动名称'
					})
					return
				}
				if(ruleForm.name.length < 4 || ruleForm.name.length > 32){
					this.$message({
						type: 'error',
						message: '活动名称字数为4-32字'
					})
					return
				}
				if(ruleForm.activity_time == ''){
					this.$message({
						type: 'error',
						message: '请选择活动时间'
					})
					return
				}
				if(ruleForm.remark == ''){
					this.$message({
						type: 'error',
						message: '请填写活动说明'
					})
					return
				}
				if(ruleForm.remark.length < 4 || ruleForm.remark.length > 64){
					this.$message({
						type: 'error',
						message: '活动说明字数为4-64字'
					})
					return
				}
				//满减限制
				if(ruleForm.consume_type == '1'){
					if(ruleForm.fullReductionCon == ''){
						this.$message({
							type: 'error',
							message: '满减：请输入满减条件'
						})
						return
					}
					if(ruleForm.fulleductionValue == ''){
						this.$message({
							type: 'error',
							message: '满减：请输入满减值'
						})
						return
					}
				}
				//折扣
				if(ruleForm.consume_type == '2'){
					if(ruleForm.discountValue == ''){
						this.$message({
							type: 'error',
							message: '折扣：请输入折扣'
						})
						return
					}
				}
				//特价
				if(ruleForm.consume_type == '3'){
					if(ruleForm.specialValue == ''){
						this.$message({
							type: 'error',
							message: '特价：请输入特价金额'
						})
						return
					}
				}
				//品牌
				if(ruleForm.shopType == '1'){
					if(this.selectBrand.length == 0){
						this.$message({
							type: 'error',
							message: '请选择品牌'
						})
						return
					}
				}
				//商品类目
				if(ruleForm.shopType == '2'){
					if(this.selectCategory.length == 0){
						this.$message({
							type: 'error',
							message: '请选择商品类目'
						})
						return
					}
				}
				//指定商品
				if(ruleForm.shopType == '3'){
					if(this.shopTable.length == 0){
						this.$message({
							type: 'error',
							message: '请选择商品'
						})
						return
					}
				}
				
				let params = {
					name:ruleForm.name,  //活动名称
					beginTime:ruleForm.activity_time[0].getTime()/1000,  //开始时间
					endTime:ruleForm.activity_time[1].getTime()/1000,  //结束时间
					activityUser:Number(ruleForm.activityUser),  //用户类型
					useCount:ruleForm.useCount,  //参与次数
					newMember:Number(ruleForm.newMember),  //是否新人专享
					autoMatching:0,
					remark:ruleForm.remark
				}
				//判断会员等级传参
				params.consumeItem = {
					consumeType: Number(ruleForm.consume_type), //消费活动类型 1满减 2折扣 3特价
					type: Number(ruleForm.shopType),  //添加产品类型  0全部商品 1品牌 2商品类目 3指定商品
				}
				
				//1满减
				if(ruleForm.consume_type == '1'){
					params.consumeItem.fullReduction = {
						fullReductionValue: Number(ruleForm.fulleductionValue),  //满减值
						fullReductionConditions: Number(ruleForm.fullReductionCon)   //满减条件
					}
					params.consumeItem.otherInfo = null;
				}else if(ruleForm.consume_type == '2'){  //2折扣
					params.consumeItem.otherInfo = {
						otherInfoValue: Number(ruleForm.discountValue)
					}
					params.consumeItem.fullReduction = null;
				}else if(ruleForm.consume_type == '3'){  //3特价
					params.consumeItem.otherInfo = {
						otherInfoValue: Number(ruleForm.specialValue)
					}
					params.consumeItem.fullReduction = null;
				}
				
				//添加商品类获取数据
				if(ruleForm.shopType == '0'){   //全部商品
					params.consumeItem.param = null
				}else if(ruleForm.shopType == '1'){  //品牌
					params.consumeItem.param = this.selectBrand
				}else if(ruleForm.shopType == '2'){  //商品类目
					params.consumeItem.param = this.selectCategory
				}else if(ruleForm.shopType == '3'){  //指定商品
					let arr = []
					this.shopTable.map((item)=>{
						arr.push({
							id:item.newData.productId,
							name:item.newData.productName,
							skuModel:item.newData.modelValue,
							barcode:item.newData.barCode,
							firstCategoryName:item.newData.firstCategoryName,
							secondCategoryName:item.newData.secondCategoryName
						})
					})
					params.consumeItem.param = arr
				}
				if(this.routerType != 2){   //新建
					this.$http.post("/cms/ActivityInfo/AddActivityInfo", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '已提交'
							})
							this.$router.push({path:'huodongList'})
						}
					})
				}else{
					let info = JSON.parse(this.activitInfo);
					params.id = info.id;
					this.$http.post("/cms/ActivityInfo/UpdateActivityInfo", params).then(res => {
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '修改成功'
							})
							this.$router.push({path:'huodongList'})
						}
					})
				}
				
				
			},
			//数组转字符串
			arrTurnStr(arr){
				let str = '';
				for(let i = 0;i<arr.length;i++){
					str+=arr[i]+','
				}
				str=(str.substring(str.length-1)==',')?str.substring(0,str.length-1):str;
				return str
			},
			//获取选中商品
			handleSelectShopChange(val){
				this.selectShopTable = val;
			},
			//商品列表批量删除
			handleBatchShopDeleteClick(){
				if(this.selectShopTable.length == 0){
					this.$message({
						type: 'error',
						message: '请选中产品'
					})
					return
				}
				for(let i = 0;i<this.selectShopTable.length;i++){
					for(let j=0;j<this.shopTable.length;j++){
						//删除商品
						if(this.selectShopTable[i].newData.productId == this.shopTable[j].newData.productId){
							this.shopTable.splice(j,1)
						}
					}
				}
			},
			//删除商品
			handelDeleteGoodsClick(index){
				//删除商品
				this.shopTable.splice(index,1)
				this.$message({
					type: 'error',
					message: '已删除'
				})
			},
			//返回商品
			submit_dialogProduct(obj){
				this.shopTable = obj.data;
				this.dialogProduct_state = false;
			},
			//删除选中商品类目
			handleCategoryClose(index) {
				this.selectCategory.splice(index, 1)
			},
			//获取选中商品类目
			handleCatgoryChange() {
				let nodesObj = this.$refs['firstcatgory'].getCheckedNodes(true)
				let categroyInfo = nodesObj.map(item => {
					return {
						id: item.value,
						name: item.label,
						skuModel: '',
						barcode: '',
						firstCategoryName: '',
						secondCategoryName: ''
					}
				})
				this.selectCategory = categroyInfo;
			},
			//获取类目列表
			getClassData() {
				let params = {
					code: 'producttype'
				}
				this.$http.get("/cms/System/GetSystemDictionTreeModel", params).then(res => {
					if (res.code === 0) {
						this.firstcatgoryData = res.data.itemList;
					}
				})
			},
			//删除选中品牌
			handleBrandClose(index) {
				this.selectBrand.splice(index, 1)
			},
			//获取选中品牌
			handleBrandChange(value) {
				if (this.selectBrand.length > 0) {
					for (let j = 0; j < this.selectBrand.length; j++) {
						if (value == this.selectBrand[j].id) {
							this.$message({
								type: 'warning',
								message: '已重复添加'
							})
							this.brandValue = '';
							return
						}
					}
				}
				for (let i = 0; i < this.brandData.length; i++) {
					if (value == this.brandData[i].id) {
						this.selectBrand.push({
							id: this.brandData[i].id,
							name: this.brandData[i].name,
							skuModel: '',
							barcode: '',
							firstCategoryName: '',
							secondCategoryName: ''
						})
					}
				}
			},
			//获取品牌列表
			getBrandData() {
				let params = {
					code: 'breedtype'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.brandData = res.data.itemList;
					}
				})
			},
			//切换活动类型 初始化
			handelConsumeTypeClick() {
				this.ruleForm.fullReductionCon = ''; //满减条件
				this.ruleForm.fulleductionValue = ''; //满减值
				this.ruleForm.discountValue = ''; //折扣
				this.ruleForm.specialValue = ''; //特价
			},
			//切换产品类型
			handelShopTypeClick() {
				this.selectBrand = [];
				this.selectCategory = [];
				this.shopTable = [];
				this.brandValue = '';
				if (this.ruleForm.shopType == '1') { //品牌
					this.getBrandData()
				} else if (this.ruleForm.shopType == '2') { //类目
					this.getClassData()
				}
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;

		.view {
			background: #fff;
			height: 100%;

			border-radius: 20px;
			display: flex;
			flex-direction: column;

			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}

			.viewC {
				flex: 1;
				display: flex;
				height: calc(100% - 136px);

				.viewCL {
					width: 582px;
					box-sizing: border-box;
					border-right: solid 1px #F2F2F2;
					overflow-y: scroll;
					padding-left: 22px;
					padding: 20px 0 20px 22px;
				}

				.viewCR {
					flex: 1;
					padding: 20px 32px;
					overflow-y: scroll;

					.comClass {
						display: flex;
						align-items: center;
						font-size: 14px;
						margin: 10px 0;

						.comTitle {
							margin-right: 10px;
						}
					}

					.comView {
						width: 94%;
						min-height: 120px;
						border: 1px solid rgba(0, 0, 0, 0.15);
						border-radius: 20px;
						padding: 10px 10px 0 10px;

						.comItem {
							margin-right: 6px;
							margin-bottom: 10px;
							display: flex;
							align-items: center;
							padding: 0 6px 0 10px;
							background: #FFF0E0;
							border: 1px solid #F77E04;
							line-height: 22px;
							font-size: 12px;
							color: #F77E04;
							display: inline-block;
							border-radius: 20px;

							span:nth-of-type(1) {
								margin-right: 10px;
							}
						}
					}
				}

				.viewCL::-webkit-scrollbar {
					display: none;
				}

				.viewCR::-webkit-scrollbar {
					display: none;
				}
			}

			.viewTitle {
				width: 64px;
				padding-left: 9px;
				font-size: 16px;
				font-weight: bold;
				background: url(../../assets/img/cangku/detailName.png) no-repeat;
				background-position: left top;
				background-size: 16px 16px;
				margin-bottom: 20px;
			}

			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}


		}
	}
</style>
